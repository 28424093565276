import _ from 'lodash';
import React, { Component } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { purify } from 'common/purify';

import I18n from 'common/i18n';
import LabeledIcon from 'common/components/LabeledIcon';
import TruncatedText from '../TruncatedText';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

/**
 * ViewCard
 * The ViewCard component renders a card used to present information about an asset (dataset, chart,
 * map, etc.). It is composed of four sections stacked vertically:
 *   - The title bar, meant to contain a clickable link to the view, an icon representing the type
 *     of the asset, and a padlock icon if the view is private.
 *   - The metadata row, displaying the day at which the view was last updated and the number of
 *     times it has been viewed.
 *   - An image, typically a preview of the view.
 *   - A description.
 * The title and description are automatically ellipsified. Buttons, spinners, or other elements
 * may be rendered as an overlay centered over the main content by specifying them as children of
 * the component.
 */

export interface ViewCardProps {
  /**
   * The children of a ViewCard are rendered as an overlay centered on top of the main content.
   * This is typically used to render buttons in the context of an asset selector, but could also
   * be used for a loading spinner. Render the component without children to avoid rendering the
   * overlay.
   */
  children?: React.ReactNode;

  /**
   * The description prop renders a description in the lower area of the ViewCard. The description
   * will automatically be ellipsified if it is longer than 3 lines of text.  If
   * this prop is omitted, the description area will render blank.
   */
  description: string;

  /**
   * The icon prop specifies an icon that will be rendered in the upper-left corner of the
   * element.  A large version of the icon will also be used as a fallback if the imageUrl prop
   * is not specified.  For a list of icons, see
   * http://socrata.github.io/styleguide/elements.html#icons.
   */
  icon: string;

  /**
   * The imageUrl prop specifies the URL of an image that will be rendered in the center of the
   * card using an <img> tag. The contents of the alt tag of the image will be the name prop. The
   * image will take up the full width of the ViewCard and will be centered vertically inside the
   * available area.
   */
  imageUrl: string;

  /**
   * These two federation-related props are for displaying a small banner on the card when the asset
   * being displayed is federated from another domain. When isFederated is true, the sourceDomain
   * prop _must_ be provided for attribution.
   */
  isFederated?: boolean;
  sourceDomain?: string;

  /**
   * If the isPrivate prop is set to true and there is no scope provided, a yellow private icon will
   * be rendered to the left of the ViewCard's title.
   */
  isPrivate: boolean;

  /**
   * The linkProps prop, if specified, should contain an object of attributes that will be merged
   * into the anchor tag that surrounds both the header and the image.  A common use case is
   * applying the target="_blank" attribute to open the link to the view in a new tab.
   */
  linkProps: any;

  /**
   * The metadataLeft prop is a piece of text that will be rendered on the left side of the
   * metadata row.
   */
  metadataLeft: string;

  /**
   * The metadataRight prop is a piece of text that will be rendered on the right side of the
   * metadata row.
   */
  metadataRight: string;

  /**
   * The name prop contains the string that will be rendered at the top of the card. It is
   * rendered as a header and links to the url prop. The header is ellipsified using
   * if it exceeds two lines in length.
   */
  name: string;

  /**
   * The onClick prop is a handler called when either the title or image are clicked. It is passed
   * the raw pooled event instance from React.
   */
  onClick: (e: React.MouseEvent) => void;

  /**
   The scope prop tells us what audiences can view the asset. Values: private, public, internal.
   */
  scope?: 'private' | 'site' | 'public';

  /**
   * The url prop contains a link to the view. It may be specified as either an absolute URL or a
   * relative URL.
   */
  url: string;
}

export class ViewCard extends Component<ViewCardProps> {
  renderOverlay() {
    if (React.Children.count(this.props.children) > 0) {
      return <div className="view-card-overlay">{this.props.children}</div>;
    }
  }

  federationSource() {
    const { isFederated, sourceDomain } = this.props;
    if (isFederated) {
      const bannerMessage = I18n.t('shared.components.view_card.data_provided_by', { sourceDomain });

      return (
        <a className="federation-banner" href={`https://${sourceDomain}`} rel="external" target="_blank">
          <SocrataIcon name={IconName.Federation} />
          <span title={bannerMessage}>{bannerMessage}</span>
        </a>
      );
    }
  }

  render() {
    const {
      description,
      icon,
      imageUrl,
      isPrivate,
      linkProps,
      metadataLeft,
      metadataRight,
      name,
      onClick,
      scope,
      url
    } = this.props;

    let scopeIcon;
    if (scope) {
      const audienceLabelText = I18n.t(scope, { scope: 'shared.asset_browser.featured_content.audience' });

      switch (scope) {
        case 'private': {
          scopeIcon = <LabeledIcon name={IconName.Private} labelText={audienceLabelText} />;
          break;
        }
        case 'site': {
          scopeIcon = <LabeledIcon name={IconName.MyOrg} labelText={audienceLabelText} />;
          break;
        }
        case 'public': {
          scopeIcon = <LabeledIcon name={IconName.PublicOpen} labelText={audienceLabelText} />;
          break;
        }
        default: {
          throw new Error('The value of `scope` in `ViewCard` is invalid.');
        }
      }
    } else if (isPrivate) {
      scopeIcon = (
        <SocrataIcon
          name={IconName.Private}
          title={I18n.t('shared.asset_browser.featured_content.audience.private')}
        />
      );
    }

    const previewImageStyling = { backgroundImage: `url(${imageUrl})` };
    const image =
      _.isString(imageUrl) && !_.isEmpty(imageUrl) ? (
        <div className="preview-image" style={previewImageStyling} title={name}>
          <span className="aria-not-displayed">Preview image</span>
        </div>
      ) : (
        <span className={`${icon} x-large-icon`}></span>
      );

    return (
      <div className="result-card media view-card">
        <div className="entry-header">
          <div className="scope-icon-holder">{scopeIcon}</div>
          <div className="entry-title">
            <div className="entry-name-holder">
              <h3 className="entry-name">
                <a
                  className="entry-name-link"
                  data-testid="view-card-entry-link"
                  {...linkProps}
                  href={url}
                  onClick={onClick}
                >
                  <TruncatedText text={name} lines={2} />
                </a>
              </h3>
            </div>
          </div>
          <div aria-hidden className="entry-view-type">
            <span className={icon} />
          </div>
        </div>

        <div className="entry-meta">
          <div className="first">
            <span className="date">{metadataLeft}</span>
          </div>
          <div className="second">
            <span className="date">{metadataRight}</span>
          </div>
        </div>

        <div className="entry-content">
          <div className="entry-main">
            <div className="img-wrapper">
              <a {...linkProps} href={url} onClick={onClick} className="img-link">
                {image}
              </a>
              {this.federationSource()}
            </div>

            <div className="entry-description">
              <HTMLEllipsis maxLine={3} unsafeHTML={purify(description)} />
            </div>
          </div>
        </div>

        {this.renderOverlay()}
      </div>
    );
  }
}

export default ViewCard;
