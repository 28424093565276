import {
  isArray as _isArray,
  get as _get,
  flatMap as _flatMap,
  flatten as _flatten,
  values as _values
} from 'lodash';
import { none, Option, option, some } from 'ts-option';
import { FeatureFlags } from 'common/feature_flags';
import { isErrorCell, isOkCell, SoQLCellOk } from 'common/components/DatasetTable/cell/TableCell';
import { cellErrorMessageToString } from 'common/dsmapi/metadataTemplate';
import {
  FieldIdentifier,
  FieldInput,
  TemplateResult,
  FieldT,
  MetadataTemplate
} from 'common/types/metadataTemplate';
import { ColumnRef } from 'common/types/soql';
import { RootState } from '../../reduxStuff/store';
import { LocalizedCategory } from 'common/core/categories';
import { AssetMetadata } from '../../types';
import { getCurrentUser } from 'common/current_user';
import UserSegment from 'common/types/users/userSegment';
import I18n from 'common/i18n';

interface DisplayNamesForField {
  fieldDisplayName: string;
  fieldSetDisplayName: string;
}

// Need a translation strategy.

const resultsForIdentifier = (templateResults: TemplateResult[], identifier: FieldIdentifier) =>
  templateResults.flatMap((templateResult) =>
    templateResult.result
      .filter(
        (result) =>
          result.name.field_name === identifier.fieldName && result.name.qualifier === identifier.qualifier
      )
      .flatMap(({ results }) => results)
  );

export const templateResultToErrors = (
  templateResults: TemplateResult[],
  identifier: FieldIdentifier
): string[] => {
  const fieldResults = resultsForIdentifier(templateResults, identifier);
  return fieldResults.flatMap((result) => {
    if (isErrorCell(result)) {
      return [cellErrorMessageToString(result)];
    }
    return [];
  });
  // TODO: multitemplate
  // TOOD: handle inconsistencies between template outputs here
};

// TODO: soql-cell-generics
export const templateResultToValue = (
  templateResults: TemplateResult[],
  identifier: FieldIdentifier
): Option<any> => {
  const okResults: SoQLCellOk[] = resultsForIdentifier(templateResults, identifier).filter(isOkCell);
  // TODO: multitemplate
  if (okResults.length > 0) {
    // Yes, some(null) is distinct from none
    return some(okResults[0].ok);
  }
  return none;
};

// TODO: soql-cell-generics
export const fieldValueForCref = (inputs: FieldInput[], cref: ColumnRef): Option<any> => {
  return option(
    inputs.find((input) => input.qualifier === cref.qualifier && input.field_name === cref.value)
  ).flatMap((fieldValue) =>
    // fv.value can be false. option(false) is a none :(
    fieldValue.value === null || fieldValue.value === undefined ? none : some(fieldValue.value)
  );
};

export const fieldParentValueForCref = (
  inputs: FieldInput[],
  cref: ColumnRef,
  coreCategories: LocalizedCategory[]
): Option<any> => {
  return option(
    inputs.find((input) => input.qualifier === cref.qualifier && input.field_name === cref.value)
  ).flatMap((fieldValue) => {
    if (fieldValue.value === null || fieldValue.value === undefined) {
      return none;
    } else {
      const category = coreCategories.find((cat) => cat.name === fieldValue.value);
      if (category?.value?.parent) {
        return some(category.value.parent);
      } else {
        return none;
      }
    }
  });
};

export const lookupLabel = (index: number, instance: FieldT, defaultValue: string): string => {
  if (FeatureFlags.value('enhance_custom_metadata')) {
    return (instance.labels_options[index] || [])[1] || defaultValue;
  }
  return defaultValue;
};

// TODO: Mentioned elsewhere, but the fact that datasetForm.errors swaps between an object and an
// array is terrible. We really should fix that. I'm changing the code here to account for that
// (it always assumes it was an object before, so it never worked), but ideally we'd get rid of the swapping completely.
export const hasDatasetErrors = (formsState: RootState['forms']): boolean => {
  const formErrors = formsState.metadataForm.errors;
  const legacyErrors = _isArray(formErrors)
    ? formErrors
    : _flatMap(formErrors, (fs) => _flatten(_values(fs.fields)));

  return legacyErrors.length > 0 || formsState.templateErrors.length > 0;
};

const searchFieldset = (
  displayNamesForField: DisplayNamesForField,
  fieldSets: {
    [key: string]: {
      [key: string]: string;
    };
  } = {}
): string | undefined => {
  return fieldSets[displayNamesForField.fieldSetDisplayName]
    ? fieldSets[displayNamesForField.fieldSetDisplayName][displayNamesForField.fieldDisplayName]
    : undefined;
};

const getDisplayNamesFromMetadataTemplate = (
  metadataTemplate: MetadataTemplate,
  fieldSetName: string,
  fieldName: string
): DisplayNamesForField => {
  let matchingField: FieldT | undefined;

  const matchingFieldSet = metadataTemplate.custom_fields.find(
    (fieldSet) => fieldSet.fieldset_qualifier === fieldSetName
  );

  if (matchingFieldSet) {
    matchingField = matchingFieldSet.fields.find((field) => field.field_name === fieldName);
  }

  // This should absolutely never happen.
  // If somehow it does, we don't want to crash, so we'll throw here and catch down below.
  if (!matchingFieldSet || !matchingField) {
    throw new Error(
      `Unable to find field in metadata template. Fieldset: ${fieldSetName}, Field: ${fieldName}. `
    );
  }

  return {
    fieldDisplayName: matchingField.display_name,
    fieldSetDisplayName: matchingFieldSet.fieldset_name
  };
};

export const getInputValueFromAssetMetadata = (
  assetMetadata: AssetMetadata,
  columnRef: ColumnRef,
  metadataTemplates: MetadataTemplate[]
): any => {
  const { builtIn, privateMetadata, customFields } = assetMetadata;

  // First check if it's a built in field.
  if (columnRef.qualifier === null) {
    switch (columnRef.value) {
      case 'resource_name': {
        return _get(builtIn, 'resourceName', null);
      }
      case 'license_id': {
        return _get(builtIn, ['license', 'licenseId'], null);
      }
      case 'row_label': {
        return _get(builtIn, 'rowLabel', null);
      }
      case 'contact_email': {
        return _get(privateMetadata, 'contactEmail', null);
      }
      case 'attachments': {
        return _get(builtIn, 'attachments', []);
      }
      case 'attribution': {
        return _get(builtIn, ['attribution', 'name'], null);
      }
      case 'attribution_link': {
        return _get(builtIn, ['attribution', 'link'], null);
      }
      default: {
        return _get(builtIn, [columnRef.value], null);
      }
    }
  }

  // If we reached this point, the column we're looking at isn't built in. Check the custom fields,
  // then the private custom fields.

  // First lets try to convert the display/human readable names used in the AssetMetadata object to the API name used
  // by the columns.
  let displayNamesForField: DisplayNamesForField;
  try {
    displayNamesForField = getDisplayNamesFromMetadataTemplate(
      metadataTemplates[0],
      columnRef.qualifier,
      columnRef.value
    );
  } catch (_error) {
    // If somehow we aren't able to match the columnRef to a field in the template something is really wrong, that
    // shouldn't be possible as we got the columnRef from there.
    // In this case we'll just set the field to null and ignore it so the app doesn't crash.
    return null;
  }

  const customFieldInputValue = searchFieldset(displayNamesForField, customFields);

  // Don't waste time checking the private custom fields if we found it already, just return.
  if (customFieldInputValue) {
    return customFieldInputValue;
  }

  return searchFieldset(displayNamesForField, privateMetadata.customFields) || null;
};

export const getErrorMessageForURLRegexFailure = (fieldContent: string): string | undefined => {
  const currentUserIsCommunity = getCurrentUser()?.userSegment === UserSegment.CommunityUser;

  // The "i" flag makes the regex case-insensitive
  const urlTestRegex = new RegExp(/.*(https:\/\/|http:\/\/|www\.)[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,}.*/gi, 'i');

  if (currentUserIsCommunity) {
    if (urlTestRegex.test(fieldContent)) {
      return I18n.t('common.dsmapi.helpers.url_not_allowed_error');
    }
  }
};
