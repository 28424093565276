import PropTypes from 'prop-types';

import QueueV2 from './QueueV2';

// NOTE: Coming here looking for TypeScript types? Check out `common/types/approvals`!

/**
 * Describes a GuidanceV2 object from core's guidanceV2 API.
 */
export default PropTypes.shape({
  publishedViewUid: PropTypes.string,
  submission: PropTypes.object, // Intentionally opaque
  queueOptions: PropTypes.arrayOf(QueueV2),
  expectedFederatedTo: PropTypes.arrayOf(PropTypes.shape({
    domainCname: PropTypes.string,
    type: PropTypes.string,
    scope: PropTypes.string,
    siteName: PropTypes.string
  })),
});

