import I18n from 'common/i18n';
const t = (k) => I18n.t(k, { scope: 'shared.asset_names' });

function getIconNameForDisplayType(displayType, isPublished = true) {
  switch (displayType) {
    case 'agent':
      return 'gateway';

    case 'schedule':
      return 'date';

    case 'filter':
    case 'grouped':
      return 'filter';

    case 'federated_href':
    case 'href':
      return 'external';

    case 'story':
      return 'story';

    case 'geomap':
    case 'intensitymap':
    case 'visualization_canvas_map':
    case 'map':
      return 'map';

    case 'annotatedtimeline':
    case 'areachart':
    case 'barchart':
    case 'chart':
    case 'columnchart':
    case 'visualization_canvas_chart':
    case 'imagesparkline':
    case 'linechart':
    case 'piechart':
      return 'bar-chart';

    case 'calendar':
      return 'calendar-alt';

    case 'form':
      return 'list2';

    case 'attachment':
    case 'blob':
    case 'file':
      return 'attachment';

    case 'blist':
    case 'dataset':
    case 'federated':
    case 'visualization_canvas_table':
    case 'table':
      return isPublished ? 'dataset' : 'working-copy';

    case 'data_asset':
      return 'db-collection';

    case 'measure':
      return isPublished ? 'measures-editor' : 'measures-editor-working-copy';

    case 'system_dataset':
      return 'settings';

    default:
      return displayType;
  }
}

const getIconClassForDisplayType = (displayType, isPublished = true) =>
  `socrata-icon-${getIconNameForDisplayType(displayType, isPublished)}`;

/**
 * Takes display type and returns forge icon that matches it
 * @param {*} displayType
 * @returns string
 */
const getForgeIconNameForDisplayType = (displayType) => {
  switch (displayType) {
    case 'agent':
      return 'transit_connection_horizontal';

    case 'schedule':
      return 'calendar';

    case 'filter':
    case 'grouped':
      return 'database_filter';

    case 'federated_href':
    case 'href':
      return 'database_arrow_right';

    case 'story':
      return 'newspaper_variant_outline';

    case 'geomap':
    case 'intensitymap':
    case 'visualization_canvas_map':
    case 'map':
      return 'map';

    case 'annotatedtimeline':
    case 'areachart':
    case 'barchart':
    case 'chart':
    case 'columnchart':
    case 'visualization_canvas_chart':
    case 'imagesparkline':
    case 'linechart':
    case 'piechart':
      return 'bar_chart';

    case 'calendar':
      return 'calendar';

    case 'form':
      return 'list_alt';

    case 'attachment':
    case 'blob':
    case 'file':
      return 'attach_file';

    case 'blist':
    case 'dataset':
    case 'federated':
    case 'visualization_canvas_table':
    case 'table':
      return 'database';

    case 'data_asset':
      return 'database_cog';

    case 'measure':
      return 'chart_timeline_variant';

    case 'system_dataset':
      return 'settings';

    default:
      return 'database'; // going to have a default otherwise the icons will break
  }
};

/**
 * Takes display type and returns a consistent translation key that matches it
 * This decouples the translation key from the icon
 * @param {*} displayType
 * @returns string
 */
const getTranslationKeyForDisplayType = (displayType) => {
  switch (displayType) {
    case 'agent':
      return 'agent';

    case 'calendar':
    case 'schedule':
      return 'calendar';

    case 'filter':
    case 'grouped':
      return 'filtered_view';

    case 'federated_href':
    case 'href':
      return 'external_dataset';

    case 'story':
      return 'story';

    case 'geomap':
    case 'intensitymap':
    case 'visualization_canvas_map':
    case 'map':
      return 'map';

    case 'annotatedtimeline':
    case 'areachart':
    case 'barchart':
    case 'chart':
    case 'columnchart':
    case 'visualization_canvas_chart':
    case 'imagesparkline':
    case 'linechart':
    case 'piechart':
      return 'chart';

    case 'form':
      return 'form';

    case 'attachment':
    case 'blob':
    case 'file':
      return 'file';

    case 'blist':
    case 'dataset':
    case 'federated':
    case 'visualization_canvas_table':
    case 'table':
      return 'dataset';

    case 'data_asset':
    case 'system_dataset':
      return 'system_dataset';

    case 'measure':
      return 'measure';

    default:
      return 'unknown'; // going to have a default otherwise translations will break
  }
};

/**
 * Takes display type and returns a translated name for that asset type
 * @param {*} displayType
 * @returns string
 */
const getAssetNameForDisplayType = (displayType) => (t(getTranslationKeyForDisplayType(displayType)));

export {
  getIconNameForDisplayType,
  getIconClassForDisplayType,
  getForgeIconNameForDisplayType,
  getAssetNameForDisplayType
};
