import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import {
  ForgeButton,
  ForgeButtonArea,
  ForgeDivider,
  ForgeExpansionPanel,
  ForgeList,
  ForgeListItem,
  ForgeOpenIcon,
  ForgeRadio
} from '@tylertech/forge-react';
import { IListItemSelectEventData } from '@tylertech/forge/esm/list';
import I18n from 'common/i18n';

export interface FacetOption {
  text: string;
  value: string;
  /** Options grouped inside the current option. Note that this component only supports one layer of nesting. */
  nestedChildren: Omit<FacetOption, 'nestedChildren'>[];
}

export interface FacetSectionProps {
  options: FacetOption[];
  title: string;
  /** Number of options (parents) to display before showing "See more" button. This number only represents the number of parents, which may have any number of children  */
  optionsDisplayCount: number;
  facetName: string;
  onFacetOptionSelect: (facetName: string, facetOptionValue: string) => void;
  onFacetClear: (facetName: string) => void;
  selectedFacetOption?: string;
}

/** Renders a title with a list of options beneath it. Clicking on the
 * title will show or hide the list of options. This commponent also can limit how many
 * options are displayed before the rest are hidden behind a "Show All" button. To show
 * all options always, set the optionsDisplayCount to a very high number.
 */
const FacetSection: FunctionComponent<FacetSectionProps> = ({
  options,
  title,
  optionsDisplayCount,
  facetName,
  onFacetOptionSelect,
  onFacetClear,
  selectedFacetOption
}) => {
  const scope = 'shared.components.facet_sidebar.facet_section';
  const sectionAttrTitle = `${title}`.trim().toLowerCase().replace(/[ ]+/gi, '-');
  const isSelectedInOptions =
    !!selectedFacetOption &&
    !!options.find((option) => {
      if (option.value == selectedFacetOption) {
        return true;
      }
      if (option.nestedChildren?.length > 0) {
        return option.nestedChildren.find((child) => {
          return child.value == selectedFacetOption;
        });
      }
      return false;
    });

  const totalOptionsCount = options.reduce(
    (sum, { nestedChildren }) => sum + (nestedChildren?.length ?? 0),
    options.length
  );

  const [isPanelExpanded, setIsPanelExpanded] = React.useState<boolean>(true);
  const [showSeeMoreButton, setSeeMoreButton] = React.useState<boolean>(
    totalOptionsCount > optionsDisplayCount
  );
  const [isAnySelected, setIsAnySelected] = React.useState<boolean>(isSelectedInOptions);

  if (isSelectedInOptions !== isAnySelected) setIsAnySelected(isSelectedInOptions);

  const filterOptionOnClick = (evt: CustomEvent<IListItemSelectEventData>) => {
    // the evt.detail.value is the filter value, i.e. datasets
    if (evt.detail.value === undefined) {
      // we don't expect this to happen, but guarding against edge cases
      return;
    } else {
      setIsAnySelected(true);
      onFacetOptionSelect(facetName, evt.detail.value);
    }
  };

  const handleClearClick = (e: Event) => {
    setIsAnySelected(false);
    onFacetClear(facetName);
  };

  const handleSeeMoreButton = (e: Event) => {
    setSeeMoreButton(false);
  };

  const handleParentRadioClick = (option: FacetOption | Omit<FacetOption, 'nestedChildren'>) => {
    if (option === undefined || option.value === undefined) {
      return;
    } else {
      setIsAnySelected(true);
      onFacetOptionSelect(facetName, option.value);
    }
  };

  const generateListItem = (option: FacetOption | Omit<FacetOption, 'nestedChildren'>, isParent = false) => {
    const isSelected = option.value == selectedFacetOption;
    const attrTitle = `${option.value}`.trim().toLowerCase().replace(/[ ]+/gi, '-');
    const labelId = `${facetName}-${attrTitle}-radio-label`;
    const slot = isParent ? 'header' : '';
    const forgeIgnore = isParent ? true : false;
    return (
      <ForgeListItem
        slot={slot}
        static={isParent}
        on-forge-list-item-select={filterOptionOnClick}
        value={option.value}
        selected={isSelected}
        key={option.value}
        wrap={true}
        forge-drawer-context={false}
      >
        <ForgeRadio slot="leading" dense={true} forge-ignore={forgeIgnore}>
          <input
            type="radio"
            name={facetName}
            onClick={() => {
              if (isParent) handleParentRadioClick(option);
            }}
            aria-labelledby={labelId}
          />
        </ForgeRadio>
        <span id={labelId} slot="title">
          {option.text}
        </span>
        {isParent && <forge-open-icon slot="trailing"></forge-open-icon>}
      </ForgeListItem>
    );
  };

  const renderInnerFacetOptions = () => {
    const listOptions: JSX.Element[] = [];
    const optionsToShow = showSeeMoreButton ? optionsDisplayCount : options.length;

    let renderedOptionsCount = 0;

    for (const option of options) {
      if (option.nestedChildren?.length > 0) {
        const childListOptions: JSX.Element[] = [];

        for (const child of option.nestedChildren) {
          childListOptions.push(generateListItem(child, false));
        }

        listOptions.push(
          <ForgeExpansionPanel key={option.text + 'expansion-panel'}>
            {generateListItem(option, true)}
            <ForgeList
              dense={true}
              key={option.value + '-children-list'}
              ariaLabel={I18n.t('choose_filter_option', { scope })}
            >
              <div className="child-list-items">{childListOptions}</div>
            </ForgeList>
          </ForgeExpansionPanel>
        );
      } else {
        listOptions.push(generateListItem(option));
      }
      renderedOptionsCount++;
      if (renderedOptionsCount >= optionsToShow) {
        break;
      }
    }

    return listOptions;
  };

  return (
    <div className="facet-expansion-panel">
      <ForgeDivider />
      <ForgeExpansionPanel
        open={isPanelExpanded}
        openCallback={() => setIsPanelExpanded(true)}
        closeCallback={() => setIsPanelExpanded(false)}
      >
        <ForgeButtonArea slot="header">
          <button
            slot="button"
            type="button"
            data-testid={'toggle-' + sectionAttrTitle + '-expansion-panel'}
            id={'toggle-' + sectionAttrTitle + '-expansion-panel'}
            aria-controls={sectionAttrTitle + '-expansion-panel-content'}
            aria-expanded={isPanelExpanded}
          >
            {I18n.t('toggle_section', { scope, facet_name: title })}
          </button>
          <div className="expansion-panel-header">
            <div className="expansion-panel-header-title forge-typography--subtitle1-secondary">{title}</div>
            {isAnySelected && (
              <ForgeButton
                type="dense"
                onClick={handleClearClick}
                data-testid={'clear-' + sectionAttrTitle + '-filter'}
                data-forge-ignore
              >
                <button type="button">
                  <span>{I18n.t('clear', { scope })}</span>
                </button>
              </ForgeButton>
            )}
            <ForgeOpenIcon />
          </div>
        </ForgeButtonArea>
        <div
          id={sectionAttrTitle + '-expansion-panel-content'}
          className="expansion-panel-content"
          role="radiogroup"
        >
          <ForgeList dense={true}>{renderInnerFacetOptions()}</ForgeList>
          {showSeeMoreButton && (
            <ForgeButton
              type="flat"
              onClick={handleSeeMoreButton}
              data-testid={'show-more-' + sectionAttrTitle}
              key="test"
            >
              <button type="button" className="show-more-button">
                <span>{I18n.t('see_more', { scope })}</span>
              </button>
            </ForgeButton>
          )}
        </div>
      </ForgeExpansionPanel>
    </div>
  );
};

export default FacetSection;
