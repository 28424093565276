import React, { useState, useRef, useEffect } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import {
  ForgeDialog,
  ForgeTabBar,
  ForgeView,
  ForgeViewSwitcher,
  ForgeTab,
  ForgeToast,
  ForgeBanner,
  ForgeStack,
  ForgeScaffold,
  ForgeToolbar,
  ForgeButton,
  ForgeIconButton,
  ForgeIcon
} from '@tylertech/forge-react';
import { ITabBarActivateEventData } from '@tylertech/forge';
import I18n from 'common/i18n';
import AccessManager, { AccessManagerProps } from 'common/components/AccessManager';
import { defaultAccessManagerOnConfirm } from 'common/components/AccessManager/Util';
import { MODES } from 'common/components/AccessManager/Constants';
import currentUserCanManageViewers from 'common/views/current_user_can_manage_viewers';
import { fetchApprovalsGuidanceV2 } from 'common/core/approvals';
import { requireApprovalRequestWithdrawalBeforeAction } from 'common/components/AssetActionBar/components/publication_action';
import ShareTab from './ShareTab';
import EmbedTab from './EmbedTab';
import { DatasetLandingPageEnhancedView } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';

export interface ShareAndEmbedProps {
  isModalOpen: boolean;
  onCloseModal: () => void;
  view: DatasetLandingPageEnhancedView;
}

const t = (key: string, options: { [key: string]: any } = {}) =>
  I18n.t(key, { scope: 'shared.components.share_and_embed', ...options });

const ShareAndEmbed: React.FunctionComponent<ShareAndEmbedProps> = ({ isModalOpen, onCloseModal, view }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showCopiedLinkToast, setShowCopiedLinkToast] = useState<boolean>(false);
  const [showAccessManager, setShowAccessManager] = useState<boolean>(false);
  const [approvalsGuidance, setApprovalsGuidance] = useState<GuidanceSummaryV2 | null>(null);
  const focusRef = useRef<HTMLDivElement>(null);

  // Forge 2 doesn't focus on ForgeDialog instances automatically on open.
  // Until we swap to Forge 3 we need to do that manually.
  const onModalOpen = () => {
    if (isModalOpen && focusRef.current) {
      focusRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('forge-dialog-open', onModalOpen);
    return () => {
      document.removeEventListener('forge-dialog-open', onModalOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getApprovalsGuidance = async () => {
      try {
        const guidance = await fetchApprovalsGuidanceV2(view.id);
        setApprovalsGuidance(guidance);
      } catch (error) {
        console.warn('Failed to get aprrovals guidance');
        setApprovalsGuidance(null);
      }
    };

    getApprovalsGuidance();
  }, []);

  const cleanUpForClose = () => {
    if (showAccessManager) {
      return;
    }
    onCloseModal();
    setActiveTab(0);
  };

  const onClickOpenAccessManager = () => {
    requireApprovalRequestWithdrawalBeforeAction(approvalsGuidance!, () => setShowAccessManager(true));
  };

  const renderPrivateAssetNotice = () => {
    if (!view.isPrivate) {
      return null;
    }

    const canShowManageViewersLink = (() => {
      if (_isEmpty(approvalsGuidance) || _isEmpty(view) || view.coreView.locked) {
        return false;
      }

      return currentUserCanManageViewers(view.coreView, approvalsGuidance!);
    })();

    const manageViewersLink = canShowManageViewersLink ? (
      <a className="manage-viewers-link" onClick={onClickOpenAccessManager} data-testid="manage-viewers-link">
        {t('private_asset_banner.link_text')}
      </a>
    ) : null;

    return (
      <ForgeBanner id="private-asset-banner" theme="warning" canDismiss={false}>
        <ForgeIcon slot="icon" name="alert" className="private-asset-banner-icon" />
        <ForgeStack inline gap={4}>
          {t('private_asset_banner.message')}
          {manageViewersLink}
        </ForgeStack>
      </ForgeBanner>
    );
  };

  const renderAccessManager = (): JSX.Element | null => {
    if (!showAccessManager) {
      return null;
    }

    const accessManagerProps: AccessManagerProps = {
      mode: MODES.CHANGE_AUDIENCE,
      view: view.coreView,
      onConfirm: defaultAccessManagerOnConfirm(() => window.location.reload(), true),
      onDismiss: () => setShowAccessManager(false)
    };

    return <AccessManager {...accessManagerProps} />;
  };

  const showModal = !showAccessManager && isModalOpen;

  return (
    <>
      <ForgeDialog open={showModal} onDismiss={cleanUpForClose} aria-labelledby="modal-title">
        <ForgeScaffold className="share-and-embed-component">
          <div slot="header">
            <ForgeToolbar>
              <div slot="start">
                <h1 id="modal-title">{t('title')}</h1>
              </div>
              <div slot="end">
                <ForgeIconButton>
                  <button
                    onClick={cleanUpForClose}
                    aria-label={t('close_button_label')}
                    data-testid="share-and-embed-header-close-button"
                  >
                    <ForgeIcon name="close" />
                  </button>
                </ForgeIconButton>
              </div>
            </ForgeToolbar>
          </div>
          <div slot="body">
            <ForgeTabBar
              className="share-and-embed-tab-bar"
              activeTab={activeTab}
              underline
              on-forge-tab-bar-activate={(event: CustomEvent<ITabBarActivateEventData>) =>
                setActiveTab(event.detail.index)
              }
            >
              <ForgeTab
                ref={focusRef}
                id="share-tab"
                aria-controls="share-view"
                data-testid="share-and-embed-share-tab"
              >
                {t('tab_titles.share')}
              </ForgeTab>
              <ForgeTab id="embed-tab" aria-controls="embed-view" data-testid="share-and-embed-embed-tab">
                {t('tab_titles.embed')}
              </ForgeTab>
            </ForgeTabBar>
            <div>
              {renderPrivateAssetNotice()}
              <ForgeViewSwitcher index={activeTab} className="share-and-embed-tabs">
                <ForgeView id="share-view" aria-labelledby="share-tab" key="shareTab">
                  <ShareTab view={view} setShowCopiedLinkToast={setShowCopiedLinkToast} />
                </ForgeView>
                <ForgeView id="embed-view" aria-labelledby="embed-tab" key="embedTab">
                  <EmbedTab
                    view={view}
                    setShowCopiedLinkToast={setShowCopiedLinkToast}
                    activeTab={activeTab}
                  />
                </ForgeView>
              </ForgeViewSwitcher>
            </div>
            <ForgeToast
              options={{
                placement: 'top',
                message: t('copied_link_success_toast_message'),
                // @ts-ignore This isn't specified in the options object, but its how it works...
                id: 'share-and-embed-copied-link-success-toast'
              }}
              open={showCopiedLinkToast}
              onDismiss={() => setShowCopiedLinkToast(false)}
            />
          </div>
          <div slot="footer">
            <ForgeToolbar inverted>
              <div slot="end">
                <ForgeButton type="outlined">
                  <button
                    onClick={cleanUpForClose}
                    type="button"
                    aria-label={t('close_button_label')}
                    data-testid="share-and-embed-footer-close-button"
                  >
                    {t('close_button_label')}
                  </button>
                </ForgeButton>
              </div>
            </ForgeToolbar>
          </div>
        </ForgeScaffold>
      </ForgeDialog>
      {renderAccessManager()}
    </>
  );
};

export default ShareAndEmbed;
